import { render, staticRenderFns } from "./bankagencies.vue?vue&type=template&id=345e77ec&scoped=true"
import script from "./bankagencies.vue?vue&type=script&lang=js"
export * from "./bankagencies.vue?vue&type=script&lang=js"
import style0 from "./bankagencies.vue?vue&type=style&index=0&id=345e77ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345e77ec",
  null
  
)

export default component.exports