<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-office-building"
                                        style="transform: scale(1.7);"></i> Bank agencies</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to manage bank agencies. </label>
                        <div v-if="addingBankAgency">
                            <button @click="addingBankAgency = false" type="button"
                                class="btn btn-outline btn-info mb-3"><span class="btn-label"><i
                                        class="mdi mdi-arrow-left-bold-box-outline" style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <form @submit.prevent="preventsubmit">
                                <div class="form-row">
                                    <b-form-group label="Agency name" label-for="bankname">
                                        <b-form-input type="text" id="bankname" v-model="agency['Agency Name']"
                                           ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" label="Email" label-for="input-sm">
                                        <b-form-input type="email" id="input-sm" v-model="agency.Email"
                                            ></b-form-input>
                                    </b-form-group>
                                </div>
                                <label class="d-block">Bank</label>
                                <multiselect v-model="agency.Bank" :options="bankNames"></multiselect>
                                <b-form-group label="Phone number" label-for="phoneNumber">
                                    <b-form-input type="number" id="phoneNumber" v-model="agency['Phone Number']"
                                        ></b-form-input>
                                </b-form-group>
                                <b-form-group label="Address" label-for="inputAddress">
                                    <b-form-input type="text" id="inputAddress" aria-required="" v-model="agency.Address"
                                        ></b-form-input>
                                </b-form-group>
                                <div class="mt-5 mb-5 text-center">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">New bank agency</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="addingBankAgency = true"><i
                                            class="ri-play-list-add-line"></i></button>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="BankAgencies" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered"></b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from "vue-multiselect";

export default {
    data() {
        return {
            addingBankAgency: false,
            bankNames: [],
            BankAgencies: [],
            agency: {
                Societe: localStorage.getItem("societe").slice(1, -1),
                "Agency Name": "",
                Address: "",
                "Phone Number": "",
                Bank: "",
                Email: "",
            },
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "Agency Name",
                    sortable: true,
                },
                {
                    key: "Bank",
                    sortable: true,
                },
                {
                    key: "Phone Number",
                    sortable: true,
                },
                {
                    key: "Email",
                    sortable: true,
                },
                {
                    key: "Address",
                    sortable: true,
                },
            ],
        }
    },
    components: {
        Layout,
        Multiselect,
    },
    computed: {
        rows() {
            return this.BankAgencies.length;
        },
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getBankAgencies() {
            this.BankAgencies = await axios.get(`https://finex.4help.tn/api/v1/getbankagencies/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
            
            console.log(this.BankAgencies)
        },
        async getBanks() {
            this.bankNames = await axios.get(`https://finex.4help.tn/api/v1/getbanks/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data.map(obj => obj.Name) })
        },
        preventsubmit(e) {
            e.preventDefault()
        },
        async handleSumbit() {
            try {
                await axios.post('https://finex.4help.tn/api/v1/addbankagency', this.agency).then(async (res) => {
                    if (res.status === 200) {
                        swal({
                            title: 'Success',
                            text: `Successfully added ${this.agency["Agency Name"]} bank!`,
                            icon: 'success',
                        });
                        this.addingBankAgency = false
                        await this.getBankAgencies()
                        this.currentPage = 1
                        this.agency = {
                            Societe: localStorage.getItem("societe").slice(1, -1),
                            "Agency Name": "",
                            Address: "",
                            "Phone number": "",
                            Bank: "",
                            Email: "",
                        }
                        

                    }
                })
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    swal({
                        title: 'Conflict',
                        text: `Bank agency already exists, please try again.`,
                        icon: 'error',
                    });
                }
                else {
                    swal({
                        title: 'Error',
                        text: `Server error.`,
                        icon: 'error',
                    });
                }
            }
        }
    },
    async mounted() {
        await this.getBanks()
        await this.getBankAgencies()
    },

}

</script>

<style scoped>


.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

tr:hover {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    transition: background-color 0.3s;
}</style>